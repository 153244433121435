import {
    ChevronUpIcon,
    ChevronDownIcon,
    UserGroupIcon,
    DocumentTextIcon,
    ClipboardDocumentListIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import Avatar from "@src/components/Avatar";
import { useQuery } from "@tanstack/react-query";
import { getSessionProgress } from "@src/api/students";
import { Link } from "react-router-dom";
import Button from "@src/components/Button";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
import "../../components/Loaders/loader.css";

export const TeamSessionTask = ({ task, session = {}, team, taskCount }) => {
    const [showMembers, setShowMembers] = useState(false);
    const { width } = useWindowDimensions();
    const { members } = team;

    // delete session.session_details;
    const submissionsArr = Object.entries(session);

    const handleShowMembersClick = () => {
        setShowMembers(!showMembers);
    };

    const taskNumber = task[0].split("_")[1];

    return (
        <>
            <div
                className={`my-2 grid w-full ${
                    showMembers || width < 866 ? `grid-cols-3` : `grid-cols-4`
                }`}
            >
                <p className="font-semibold">{`Task ${taskNumber}`}</p>
                {showMembers || width < 866 ? (
                    <div></div>
                ) : (
                    <div className="col-span-2 flex grid w-full grid-cols-3 flex-row ">
                        <>
                            <div className="mr-2 flex flex-row items-center">
                                <ClipboardDocumentListIcon
                                    color="gray"
                                    className="h-6 w-6"
                                />
                                <p className="ml-2">{`${
                                    taskCount > 1
                                        ? `${taskCount} Tasks`
                                        : `${taskCount} Task`
                                }`}</p>
                            </div>
                            <div className="mr-2 flex flex-row items-center">
                                <UserGroupIcon
                                    color="gray"
                                    className="h-6 w-6"
                                />
                                <p className="ml-2">{`${
                                    members.length > 1
                                        ? `${members.length} Members`
                                        : `${members.length} Member`
                                }`}</p>
                            </div>
                            <div className="ml-2 flex flex-row items-center">
                                <DocumentTextIcon
                                    color="gray"
                                    className="h-6 w-6"
                                />
                                <p className="ml-2">{`${
                                    submissionsArr.length - 1 > 1
                                        ? `${submissionsArr.length - 1} Tasks`
                                        : `${submissionsArr.length - 1} Task`
                                }`}</p>
                            </div>
                        </>
                    </div>
                )}

                <div className="flex flex-row items-center justify-end">
                    <div
                        className="cursor-pointer rounded-sm p-2 hover:bg-slate-50"
                        onClick={handleShowMembersClick}
                    >
                        {showMembers ? (
                            <ChevronUpIcon className="h-6 w-6" />
                        ) : (
                            <ChevronDownIcon className="h-6 w-6" />
                        )}
                    </div>
                </div>
            </div>
            {showMembers && (
                <div className="mt-4 flex w-full flex-col">
                    <>
                        <div className="flex w-full flex-row items-center justify-between px-5">
                            <p>Members</p>
                            <p>Submissions</p>

                            <p className="min-w-16">Actions</p>
                        </div>
                        <hr className="mt-3 border-slate-300" />
                    </>
                    {members.map((member, idx) => {
                        return (
                            <SubmissionItem
                                member={member}
                                session={session}
                                key={idx}
                                isFirst={false}
                                task={task}
                                team={team}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

export const SubmissionItem = ({
    member,
    session = {},
    isFirst = false,
    task,
    team,
}) => {
    const { id, full_name } = member;
    const [submissionsArray, setSubmissionsArray] = useState([]);

    const { isLoading, data } = useQuery({
        queryKey: ["students", "session-progress", id],
        queryFn: async () => await getSessionProgress(id),
        enabled: Boolean(member.id),
    });

    useEffect(() => {
        if (isFirst) {
            const { submissions } = session;
            const submissionsArr = Object.entries(submissions);
            setSubmissionsArray(submissionsArr);
        } else if (!isFirst && data) {
            const submissionsArr = Object.entries(session);
            setSubmissionsArray(submissionsArr);
        }
    }, [isFirst, session, isLoading]);

    if (isLoading) {
        return (
            <div className="flex w-full items-center justify-center p-4">
                <span className="loader "></span>
            </div>
        );
    }

    console.log("SUBMISSION DATA LOADING ----> ", isLoading);

    if (isLoading) {
        return (
            <div className="flex w-full items-center justify-center p-4">
                <span className="loader "></span>
            </div>
        );
    }

    return (
        // className="flex w-full flex-row items-center justify-between p-5"
        <div className="grid w-full grid-cols-3 border-b p-5 ">
            <div className="flex flex-row items-center place-self-start">
                <Avatar size="32" name={full_name} />
                <p className="ml-2">{full_name}</p>
            </div>
            <div className="mr-11 flex min-w-12 items-center justify-center ">
                <p>{submissionsArray.length}</p>
            </div>
            {/* to={{ pathname: `/school/individual-submissions`, state: dataToPass }} */}

            <Link
                to="/school/individual-submissions"
                state={{ session, isFirst, data, task, member, team }}
                className="place-self-end"
            >
                <Button
                    variant="outlined"
                    intent="white"
                    type="button"
                    size="md"
                >
                    View
                </Button>
            </Link>
        </div>
    );
};

export default TeamSessionTask;
