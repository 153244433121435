import { Menu } from "@headlessui/react";
import { cx } from "class-variance-authority";
import Avatar from "../Avatar";
import useStore from "@store/useStore";
import { Link } from "react-router-dom";
import { userTypeRootRoute } from "@src/api/auth";

const UserAvatarMenu = () => {
    const user = useStore((state) => state.user);

    if (user == null) {
        throw Error("Cannot render UserAvatarMenu without User object.");
    }

    return (
        <Menu as="div" className="relative inline-block">
            <Menu.Button id="avatar-profile-button">
                <Avatar
                    src={user.avatar ?? undefined}
                    name={user.full_name}
                    size="50"
                />
            </Menu.Button>
            <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                    <Menu.Item>
                        {({ active }) => (
                            <Link
                                to={`${userTypeRootRoute(
                                    user?.user_type
                                )}/profile`}
                                className={cx(
                                    active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                )}
                            >
                                Profile
                            </Link>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <Link
                                className={cx(
                                    active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                )}
                                to="/logout"
                            >
                                Logout
                            </Link>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    );
};

export default UserAvatarMenu;
