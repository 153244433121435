import {
    passwordResetConfirm,
    resendAccountSetupEmail,
    userTypeHomeRoute,
    userTypeOnboardingRoute,
} from "@api/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@src/components/Alert";
import Button from "@src/components/Button";
import {
    FieldError,
    GeneralError,
    Label,
    PasswordField,
} from "@src/components/Form";
import AuthLayout from "@src/components/layout/AuthLayout";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import useStore from "@store/useStore";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

function isInvalidTokenError(errorMessage: string) {
    return errorMessage.toLowerCase().includes("invalid token");
}

function invalidTokenErrorMessage(requestNewToken: () => Promise<void>) {
    return (
        <>
            <p>
                Invalid token provided in the link. You can request for a new
                one to access your account.
            </p>
            <Button
                onClick={requestNewToken}
                variant={"text"}
                intent={"danger"}
                size={"sm"}
            >
                Request for new token
            </Button>
        </>
    );
}

export default function PasswordResetConfirm() {
    const [searchParams] = useSearchParams();

    const { uid, token: resetToken } = useParams();

    const authenticate = useStore((state) => state.authenticate);

    const navigate = useNavigate();

    const [isAccountSetup, setIsAccountSetup] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>("");

    useEffect(() => {
        if (searchParams.get("source") != null) {
            setIsAccountSetup(searchParams.get("source") === "account_setup");
        }
    }, []);

    const schema = z
        .object({
            password: z.string(),
            password_confirm: z.string(),
        })
        .refine((data) => data.password === data.password_confirm, {
            message: "Passwords don't match",
            path: ["password_confirm"],
        });

    type PasswordConfirmFormData = ReturnType<typeof schema.parse>;
    type PasswordConfirmFormDataWithGeneral = PasswordConfirmFormData & {
        general: never;
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm<PasswordConfirmFormDataWithGeneral>({
        resolver: zodResolver(schema),
    });

    const onSubmit = async (data: PasswordConfirmFormData) => {
        try {
            const response = await passwordResetConfirm(
                uid,
                resetToken,
                data,
                isAccountSetup
            );

            clearErrors();
            const { user } = response.data;
            authenticate(user);

            if (isAccountSetup) {
                navigate(userTypeOnboardingRoute(user.user_type), { replace: true });
                return;
            }

            navigate(userTypeHomeRoute(user.user_type), { replace: true });
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    };

    async function requestNewToken() {
        try {
            const response = await resendAccountSetupEmail(uid);
            setSuccessMessage(response.data.msg);
            clearErrors();
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    }

    return (
        <AuthLayout>
            <div className="max-w-lg px-4 pt-8 pb-10 mx-auto mt-10 bg-white rounded-md shadow-md sm:px-6 md:px-8">
                <h1 className="text-xl font-semibold text-gray-800">
                    {isAccountSetup
                        ? "Account Password Setup"
                        : "Password Reset Confirmation"}
                </h1>

                {isAccountSetup && (
                    <p className={"text-gray-600 mt-1"}>
                        Setup a secure password to access the platform
                    </p>
                )}

                <GeneralError
                    className="mt-4"
                    message={
                        isInvalidTokenError(errors.general?.message ?? "")
                            ? invalidTokenErrorMessage(requestNewToken)
                            : errors.general?.message
                    }
                />

                {successMessage !== "" && (
                    <Alert
                        className="mt-4"
                        intent="success"
                        title={"Successful new token request"}
                        description={successMessage}
                    />
                )}

                <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <Label htmlFor="password" name="New Password" />
                        <PasswordField
                            {...register("password")}
                            id="password"
                            className="w-full mt-1"
                            autoFocus
                            autoComplete="off"
                            aria-invalid={!(errors.password == null)}
                            required
                        />
                        <FieldError
                            className="mt-1"
                            message={errors.password?.message}
                        />
                    </div>
                    <div className="mt-4">
                        <Label
                            htmlFor="password-confirm"
                            name="Confirm New Password"
                        />
                        <PasswordField
                            {...register("password_confirm")}
                            className="w-full mt-1"
                            id="password-confirm"
                            autoComplete="off"
                            aria-invalid={!(errors.password_confirm == null)}
                            required
                        />
                        <FieldError
                            className="mt-1"
                            message={errors.password_confirm?.message}
                        />
                    </div>
                    <div className="flex justify-end mt-8">
                        <Button type="submit">
                            {isAccountSetup ? "Setup" : "Reset"} Password
                        </Button>
                    </div>
                </form>
            </div>
        </AuthLayout>
    );
}
