import { getFireside, getReflectionQuestions } from "@src/api/firesides";
import PageHeader from "@src/components/PageHeader";
import SpeakerAvatar from "@src/components/SpeakerAvatar";
import { type LoaderReturnType } from "@src/utils/type_helpers";
import { type QueryClient } from "@tanstack/query-core";
import {
    useLoaderData,
    useRouteLoaderData,
    Navigate,
    type LoaderFunctionArgs,
} from "react-router";
import Video, { VideoType } from "@components/Content/Video";
import { type User } from "@src/models/types";
import { AccountStatus } from "@src/models";
import Alert from "@src/components/Alert";
import ReflectionQuestionsForm from "@src/components/fireside/ReflectionQuestionsForm";

export function loader(queryClient: QueryClient) {
    return async ({ params }: LoaderFunctionArgs) => {
        const slug = params.slug ?? "";

        const fireside = await queryClient.ensureQueryData({
            queryKey: ["fireside", slug],
            queryFn: async () => await getFireside(slug),
        });

        const questions = await queryClient.ensureQueryData({
            queryKey: ["fireside", slug, "reflection-questions"],
            queryFn: async () => await getReflectionQuestions(slug),
        });

        return { fireside, questions };
    };
}

const ViewFireside = () => {
    const { fireside, questions } = useLoaderData() as LoaderReturnType<
        typeof loader
    >;

    const user = useRouteLoaderData("root") as User;

    if (
        user.account_status === AccountStatus.FREE_TRIAL &&
        fireside.is_premium
    ) {
        return <Navigate to={"/student/firesides"} replace={true} />;
    }

    const breadcrumbPaths = [
        {
            name: "Fireside Chats",
            href: "/student/firesides",
        },
        {
            name: fireside.title,
            href: `/student/firesides/${fireside.slug}`,
        },
    ];

    return (
        <>
            <PageHeader
                title={"Fireside Chat"}
                breadcrumbPaths={breadcrumbPaths}
            />

            <section className="pb-20">
                <section className="mx-auto max-w-6xl px-4 py-10 sm:px-6 md:px-8">
                    <div>
                        <h3 className="text-4xl font-semibold">
                            {fireside.title}
                        </h3>
                        <div className="mt-4 flex items-center space-x-4">
                            <SpeakerAvatar
                                speakerAvatar={fireside.speaker.avatar}
                                speakerName={fireside.speaker.name}
                                className="h-20 w-20"
                            />
                            <div className="text-lg text-gray-800">
                                <p className="font-semibold">
                                    {fireside.speaker.name}
                                </p>
                                <p>{fireside.speaker.title}</p>
                            </div>
                        </div>
                    </div>
                    <section>
                        <Video
                            title={fireside.title}
                            className="mt-10 aspect-video max-w-5xl"
                            src={fireside.video_url}
                            videoType={[VideoType.Fireside, 0, fireside.slug]}
                        />
                    </section>
                </section>

                {questions.length > 0 && (
                    <section className="mx-auto mt-4 max-w-6xl px-4 sm:px-6 md:px-8">
                        <Alert
                            className="max-w-5xl"
                            intent={"info"}
                            title={"Great job watching the fireside chat!"}
                            description={
                                "Take some time to reflect on what you learned by answering the provided reflection questions. These questions will help you gain a deeper understanding of the entrepreneur's experiences and insights and how you can apply them to your own life and goals."
                            }
                        />

                        <ReflectionQuestionsForm
                            className="mt-8"
                            questions={questions}
                            firesideId={fireside.id}
                            studentId={user.id}
                        />
                    </section>
                )}
            </section>
        </>
    );
};

export default ViewFireside;
