import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { searchStudents } from "@src/api/students";
import { type Student } from "@src/models/types";
import { responseErrorToFormErrors } from "@src/utils/form-errors";
import clsx from "clsx";
import { debounce } from "lodash-es";
import type React from "react";
import { Fragment, useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import TextField from "./TextField";
import type { FieldValues, UseFormSetError } from "react-hook-form";

interface UserSearchBoxProps {
    label: string;
    selectedUser: Student | null;
    setSelectedUser: React.Dispatch<React.SetStateAction<Student | null>>;
    setError: UseFormSetError<FieldValues>;
}

const UserSearchBox = ({
    label,
    selectedUser,
    setSelectedUser,
    setError,
}: UserSearchBoxProps) => {
    const currentUser = useRouteLoaderData("root") as Student;

    const [query, setQuery] = useState("");

    const [filteredUserList, setFilteredUserList] = useState<Student[] | []>(
        []
    );

    const searchUsers = debounce(async () => {
        try {
            const res = await searchStudents(query);

            const filtered = res.data.filter((student: Student) => {
                if (currentUser.id === student.id) {
                    return false;
                }

                return student.teams.length <= 0;
            });

            setFilteredUserList(filtered);
        } catch (error) {
            responseErrorToFormErrors(error, setError);
        }
    }, 300);

    useEffect(() => {
        setSelectedUser(null);

        (async () => await searchUsers())();

        return () => {
            searchUsers.cancel();
        };
    }, [query, currentUser]);

    return (
        <Combobox
            as="div"
            className={"flex-1"}
            value={selectedUser}
            onChange={setSelectedUser}
        >
            <Combobox.Label className="block font-medium text-gray-700">
                {label}
            </Combobox.Label>
            <div className="relative mt-1">
                <Combobox.Input
                    as={Fragment}
                    onChange={(event) => {
                        setQuery(event.target.value);
                    }}
                    displayValue={(user: Student) =>
                        user !== null
                            ? `${user?.first_name} ${user?.last_name}`
                            : ""
                    }
                >
                    <TextField
                        type={"text"}
                        autoComplete={"off"}
                        className={"w-full"}
                        placeholder={"Enter search query ..."}
                        required={true}
                    />
                </Combobox.Input>
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                    <ChevronUpDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>

                {filteredUserList.length > 0 && (
                    <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredUserList.map((user) => (
                            <Combobox.Option
                                key={user.id}
                                value={user}
                                className={({ active }) =>
                                    clsx(
                                        "relative cursor-default select-none py-2 pl-8 pr-4",
                                        active
                                            ? "bg-purple-600 text-white"
                                            : "text-gray-900"
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span
                                            className={clsx(
                                                "block truncate",
                                                selected && "font-semibold"
                                            )}
                                        >
                                            {`${user.first_name} ${user.last_name}`}

                                            <span
                                                className={clsx(
                                                    "ml-2 truncate text-gray-500",
                                                    active
                                                        ? "text-indigo-200"
                                                        : "text-gray-500"
                                                )}
                                            >
                                                {user.email}
                                            </span>
                                        </span>

                                        {selected && (
                                            <span
                                                className={clsx(
                                                    "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                                    active
                                                        ? "text-white"
                                                        : "text-indigo-600"
                                                )}
                                            >
                                                <CheckIcon
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default UserSearchBox;
