import { Popover, Transition } from "@headlessui/react";
import {
    BellIcon,
    BellAlertIcon,
    CheckIcon,
} from "@heroicons/react/24/outline";
import {
    getNotifications,
    markAllNotificationsRead,
    markNotificationAsRead,
} from "@api/notifications";
import Button from "../Button";
import { type MouseEvent, useEffect, useState, Fragment } from "react";
import { type Notification } from "@src/api/types";
import { timeSince } from "@src/utils";
import { useStore } from "@src/store";
import { useNavigate } from "react-router-dom";

const stringReprObject = (object: {
    object_type: string;
    [key: string]: any;
}) => {
    switch (object?.object_type) {
        case "User": {
            const actorName = `${object.first_name} ${object.last_name}`;
            return actorName.trim() !== "" ? actorName : object.email;
        }
        case "Team":
            return object.team_name;
        case "SessionContent":
        case "Session":
            return object.title;
        case "Submission":
            return `${object.task_identifier.replace("_", " ")} of ${
                object.session_title
            }`;
        default:
            return object?.object_type;
    }
};

const parseNotification = (notification: Notification) => {
    switch (notification.actor?.object_type) {
        case "Announcement":
            return notification.verb;
        default:
            return `${stringReprObject(notification.actor)} ${
                notification.verb
            } ${
                notification.target != null
                    ? stringReprObject(notification.target)
                    : ""
            } ${
                notification.action_object != null
                    ? stringReprObject(notification.action_object)
                    : ""
            }`.trim();
    }
};

const NotificationsPopover = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [error, setError] = useState("");
    const user = useStore((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAndSetNotifications = async () => {
            const notifications = await getNotifications();

            // we should not show notifications if from the current user
            // since it's now possible to post comments on feedback from parents/facilitators as a student
            if (user != null) {
                setNotifications(
                    notifications.filter(
                        (notification) => notification.actor?.id !== user.id
                    )
                );
            } else {
                setNotifications(notifications);
            }
        };

        void fetchAndSetNotifications();

        const id = setInterval(fetchAndSetNotifications, 15000);
        return () => {
            clearInterval(id);
        };
    }, []);

    const triggerError = (error: string) => {
        setError(error);
        setTimeout(() => {
            setError("");
        }, 5000);
    };

    async function markAllAsRead() {
        try {
            const done = await markAllNotificationsRead();

            if (!done) {
                triggerError(
                    "An error occurred while marking notifications as read!"
                );
                return;
            }

            setNotifications([]);
        } catch (error) {
            triggerError(
                "An error occurred while marking notifications as read!"
            );
        }
    }

    async function markAsRead(
        event: MouseEvent,
        idx: number,
        notification: Notification
    ) {
        event.preventDefault();
        try {
            const done = await markNotificationAsRead(notification.id);

            if (done) {
                if (idx === 0) {
                    setNotifications([...notifications.slice(idx + 1)]);
                } else {
                    setNotifications([
                        ...notifications.slice(0, idx),
                        ...notifications.slice(idx + 1),
                    ]);
                }
                return;
            }
            triggerError(
                "An error occurred while attempting to mark the notification as read!"
            );
        } catch (error) {
            triggerError(
                "An error occurred while attempting to mark the notification as read!"
            );
        }
    }

    /**
     * navigate to section pertaining to notification
     * @param notification
     * @returns
     * */
    const navigateToNotification = (notification: Notification) => {
        const { target } = notification;
        const url = `/student/content/${target?.session_id}/quiz?step=${
            target?.task_identifier.split("_")[1]
        }`;

        navigate(url, { state: { scrollToComments: true } });
    };

    return (
        <Popover className="relative mr-2">
            <Popover.Button
                as={Button}
                variant="text"
                intent="white"
                size="sm"
                type="button"
                title="View Notifications"
            >
                {notifications.length > 0 ? (
                    <>
                        <BellAlertIcon className="h-8 w-8" />
                        <span className="relative flex h-3 w-3 self-start">
                            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-purple-400 opacity-75"></span>
                            <span className="relative inline-flex h-3 w-3 rounded-full bg-purple-500"></span>
                        </span>
                    </>
                ) : (
                    <BellIcon className="h-8 w-8" />
                )}
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute right-0 z-10 mt-3 w-96 md:w-[32rem] lg:w-[40rem]">
                    <div className="overflow-hidden rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="flex items-center justify-between border-b p-4">
                            <span className="text-lg font-bold">
                                Notifications
                            </span>

                            {notifications.length > 0 && (
                                <Button
                                    variant="text"
                                    intent="primary"
                                    size="sm"
                                    type="button"
                                    onClick={markAllAsRead}
                                >
                                    Mark All as Read
                                </Button>
                            )}
                        </div>
                        {Boolean(error) && (
                            <div className="bg-red-100 px-2 py-4 text-center text-base text-red-700">
                                {error}
                            </div>
                        )}

                        <ul role="list" className={"divide-y"}>
                            {notifications.map((notification, idx) => (
                                <li key={notification.id}>
                                    <div
                                        className={
                                            "flex items-center justify-between px-6 py-4 text-gray-900"
                                        }
                                    >
                                        <div className="flex flex-col">
                                            <button
                                                className="rounded-md p-2 text-left hover:bg-gray-50"
                                                onClick={() => {
                                                    navigateToNotification(
                                                        notification
                                                    );
                                                }}
                                            >
                                                {parseNotification(
                                                    notification
                                                )}
                                            </button>
                                            <span className="pl-2 text-sm text-gray-500">
                                                {timeSince(
                                                    new Date(
                                                        notification.timestamp
                                                    )
                                                )}{" "}
                                                ago
                                            </span>
                                        </div>
                                        <Button
                                            variant="outlined"
                                            intent="white"
                                            size="sm"
                                            type="button"
                                            title="Mark as Read"
                                            className="ml-4"
                                            onClick={async (
                                                event: MouseEvent
                                            ) => {
                                                await markAsRead(
                                                    event,
                                                    idx,
                                                    notification
                                                );
                                            }}
                                        >
                                            <CheckIcon className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        {notifications.length === 0 && (
                            <div className="px-2 py-4 text-center text-base">
                                Nothing to see here, you are all caught up!
                            </div>
                        )}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default NotificationsPopover;
