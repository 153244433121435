import {
    type AuthSlice,
    type AuthSliceState,
    type SliceCreator,
} from "@store/types";
import { logout as authLogout } from "@src/api/auth";

const initialState: AuthSliceState = {
    isAuthenticated: false,
    user: null,
};

export const createAuthSlice: SliceCreator<AuthSlice> = (set) => ({
    ...initialState,

    logout: async (callBack) => {
        try {
            await authLogout();
            set(initialState);

            if (callBack != null) {
                callBack();
            }
        } catch (error: any) {
            throw new Error(
                `An error occured while attempting to logout: ${error.message}`
            );
        }
    },

    authenticate: (user) => {
        set({
            isAuthenticated: true,
            user,
        });
    },
});
