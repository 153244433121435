import { Navigate, Outlet } from "react-router-dom";
import { useStore } from "@src/store";
import { userTypeHomeRoute } from "@src/api/auth";

export default function GuestRoutes() {
    const isAuthenticated = useStore.getState().isAuthenticated;
    const user = useStore.getState().user;

    if (isAuthenticated && (user != null)) {
        return <Navigate to={userTypeHomeRoute(user.user_type)} replace />;
    }

    return <Outlet />;
}
