import { useLoaderData } from "react-router-dom";
import { studentSessions } from "@src/api/sessions";
import PageHeader from "@src/components/PageHeader";
import SessionCard from "./SessionCard";
import UXSurvey from "@src/components/Survey/UXSurvey";

const breadcrumbPaths = [{ name: "Classroom", href: "/student/content" }];

const MainContent = () => {
    const sessions = useLoaderData();

    return (
        <>
            <PageHeader title="Classroom" breadcrumbPaths={breadcrumbPaths} />
            <UXSurvey/>
            <section className="px-4 py-6 mx-auto max-w-7xl sm:px-6 md:px-8">
                <ul
                    data-cy="sessions-container"
                    className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                >
                    {sessions.map((session, idx) => (
                        <li key={idx}>
                            <SessionCard session={session} />
                        </li>
                    ))}
                </ul>
            </section>
        </>
    );
};

export default MainContent;

export function loader() {
    return studentSessions().then((res) => res.data);
}
