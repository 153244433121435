import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "@components/Button";
import { Tab } from "@headlessui/react";
import TeamInvitesTab from "@src/containers/student-onboarding/TeamInvitesTab";
import TeamSetupTab from "@src/containers/student-onboarding/TeamSetupTab";
import TeamSummaryTab from "@src/containers/student-onboarding/TeamSummaryTab";
import Video from "@src/components/Content/Video";

const StudentOnboarding = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const next = () => {
        if (currentIndex === 5) {
            return;
        }

        setCurrentIndex(currentIndex + 1);
    };

    const previous = () => {
        if (currentIndex === 0) {
            return;
        }
        setCurrentIndex(currentIndex - 1);
    };

    return (
        <section className={`flex min-h-screen flex-col`}>
            <header className="bg-white shadow-md">
                <div className="flex items-center px-4 py-3 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <Link to="/">
                        <img
                            className="h-14"
                            src="/images/kineticp.png"
                            alt="Kinetic Platform"
                        />
                    </Link>

                    <Button
                        className="ml-auto"
                        intent={"white"}
                        href="/logout"
                    >
                        Logout
                    </Button>
                </div>
            </header>

            <main className="flex-1 pb-12 bg-gray-50 sm:pb-0">
                <section className={"mx-auto max-w-5xl"}>
                    <Tab.Group
                        as={"div"}
                        selectedIndex={currentIndex}
                        onChange={setCurrentIndex}
                    >
                        <Tab.List className={"hidden space-x-2"}>
                            <Tab>Welcome</Tab>
                            <Tab>Watch Introduction Video</Tab>
                            <Tab>Team Setup Guide Video</Tab>
                            <Tab>Create team</Tab>
                            <Tab>Send team invites</Tab>
                            <Tab>Team Setup Completed</Tab>
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <div className="px-4 py-6 mt-6 bg-white rounded-md shadow sm:px-6 md:px-8">
                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                        <div
                                            className={
                                                "flex items-center justify-center bg-orange-50"
                                            }
                                        >
                                            <img
                                                className="h-64 w-[50%] object-contain md:w-[100%]"
                                                src="/images/welcome-note.svg"
                                                alt="Sticky note with the message 'Hello, welcome to kinetic'"
                                            />
                                        </div>

                                        <div className="flex justify-center min-h-max md:col-span-2">
                                            <img
                                                className="object-contain h-96"
                                                src="/images/welcome-avatars.svg"
                                                alt="Three smiling students"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-end mt-6">
                                        <Button onClick={next}>
                                            Watch Introduction Video
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel>
                                <div
                                    className={"px-4 pb-3 pt-6 sm:px-6 md:px-8"}
                                >
                                    <h1
                                        className={
                                            "text-3xl font-semibold text-purple-700"
                                        }
                                    >
                                        Introduction Video
                                    </h1>

                                    <div className={"my-4 md:my-8"}>
                                        <Video
                                            className="w-full aspect-video"
                                            title="Kinetic Platform Introduction Video"
                                            src="https://player.vimeo.com/video/766891621?h=71d491d069"
                                        />
                                    </div>

                                    <div className={"flex justify-end"}>
                                        <Button onClick={next}>
                                            Watch the team setup guide video
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Panel>
                            <Tab.Panel>
                                <div
                                    className={"px-4 pb-3 pt-6 sm:px-6 md:px-8"}
                                >
                                    <h1
                                        className={
                                            "text-3xl font-semibold text-purple-700"
                                        }
                                    >
                                        Team Setup Guide Video
                                    </h1>

                                    <div className={"my-4 md:my-8"}>
                                        <Video
                                            className="w-full aspect-video"
                                            title="Kinetic Team Setup Guide Video"
                                            src="https://player.vimeo.com/video/817555991?h=9fec5b32fb"
                                        />
                                    </div>

                                    <div className={"flex justify-end"}>
                                        <Button onClick={next}>
                                            Start setting up your team
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel
                                as={TeamSetupTab}
                                next={next}
                                previous={previous}
                            />

                            <Tab.Panel
                                as={TeamInvitesTab}
                                next={next}
                                previous={previous}
                            />

                            <Tab.Panel
                                as={TeamSummaryTab}
                                next={next}
                                previous={previous}
                            />
                        </Tab.Panels>
                    </Tab.Group>
                </section>
            </main>
        </section>
    );
};

export default StudentOnboarding;
