import {
    DocumentChartBarIcon,
    FireIcon,
    TrophyIcon,
    UsersIcon,
    TvIcon,
} from "@heroicons/react/24/outline";
import MainLayout, { type MainLayoutProps } from "./MainLayout";
import SideBar, { type SideBarNavItem } from "../Navbar/SideBar";
import { useRouteLoaderData } from "react-router-dom";
import type { User } from "@src/models/types";
import { AccountStatus } from "@src/models";
import FreeTrialCountdownBanner from "../FreeTrialCountdownBanner";
import BadgeModal from "../Badge/BadgeModal";

export interface StudentLayoutProps extends MainLayoutProps {}

const navItems: SideBarNavItem[] = [
    {
        name: "Classroom",
        link: "/student/content",
        icon: TvIcon,
    },
    {
        name: "My Team",
        link: "/student/team",
        icon: UsersIcon,
    },
    {
        name: "Fireside",
        link: "/student/firesides",
        icon: FireIcon,
    },
    {
        name: "League",
        link: "/student/league/team",
        icon: TrophyIcon,
    },
    {
        name: "Reports",
        link: "/student/reports",
        icon: DocumentChartBarIcon,
    },
];

export default function StudentLayout({ children }: StudentLayoutProps) {
    const user = useRouteLoaderData("root") as User;
    const isFreeTrial = user.account_status === AccountStatus.FREE_TRIAL;

    return (
        <MainLayout sidebar={<SideBar navItems={navItems} />}>
            {isFreeTrial && (
                <FreeTrialCountdownBanner className="absolute left-[50%] top-0 z-10" />
            )}
            <BadgeModal />
            {children}
        </MainLayout>
    );
}
