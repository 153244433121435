import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import Button from "@src/components/Button";
import TeamSessionTask from "./TeamSessionTask";
import tasks from "../../containers/activities/SessionOne/tasks_schema.json";
import { Link } from "react-router-dom";
import Avatar from "@src/components/Avatar";
import "../../components/Loaders/loader.css";

// Disclosure for session one since they are individual submissions
const SessionOneDisclosure = ({ team }) => {
    const [openTask, setOpenTask] = useState("");
    const { members } = team;

    const handleOpenTask = (taskLabel) => {
        setOpenTask(taskLabel);
    };

    return (
        <Disclosure as="div" className="mt-2 px-2">
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex h-14 w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                        <span className="font-semibold">{`Session 1`}</span>

                        <div className="flex flex-row items-center">
                            <Button
                                variant="outlined"
                                intent="white"
                                type="button"
                                size="md"
                                className="mr-6 hidden"
                            >
                                Download
                            </Button>

                            <ChevronUpIcon
                                className={`${
                                    open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-purple-500`}
                            />
                        </div>
                    </Disclosure.Button>

                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                        {tasks.map((task, index) => {
                            return (
                                <div className="flex w-full flex-col ">
                                    <div className="flex flex-row items-center justify-between py-4">
                                        <p className="font-semibold">{`Task ${
                                            index + 1
                                        }`}</p>
                                        <div
                                            onClick={() =>
                                                handleOpenTask(task.label)
                                            }
                                        >
                                            {task.label === openTask ? (
                                                <ChevronDownIcon className="h-6 w-6" />
                                            ) : (
                                                <ChevronUpIcon className="h-6 w-6" />
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {members.map((member, idx) => {
                                            return (
                                                <div
                                                    className={`grid w-full grid-cols-3 border-b p-5 ${
                                                        task.label === openTask
                                                            ? "visible"
                                                            : "hidden"
                                                    }`}
                                                    key={idx}
                                                >
                                                    <div className="flex flex-row items-center place-self-start">
                                                        <Avatar
                                                            size="32"
                                                            name={
                                                                member.full_name
                                                            }
                                                        />
                                                        <p className="ml-2">
                                                            {member.full_name}
                                                        </p>
                                                    </div>
                                                    <div className="mr-11 flex min-w-12 items-center justify-center ">
                                                        <></>
                                                    </div>

                                                    <Link
                                                        to="/school/session-one-submission"
                                                        state={{
                                                            task,
                                                            member,
                                                            team,
                                                        }}
                                                        className="place-self-end"
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            intent="white"
                                                            type="button"
                                                            size="md"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

// Submissions for the team
const TeamSessions = ({ sessions = [], team = {} }) => {
    console.log();
    return (
        <div className="w-full ">
            {/* Session one Disclosure */}
            <SessionOneDisclosure team={team} sessions={sessions} />

            {sessions.length <= 0 && (
                <div className="flex w-full items-center justify-center p-4">
                    <span className="loader "></span>
                </div>
            )}

            <div className="mx-auto w-full rounded-2xl bg-white p-2">
                {sessions.map((session, idx) => {
                    const sessionData = { ...session };

                    const sortOrder = session.session_details.sort_order;
                    delete sessionData.session_details;
                    const tasksArray = Object.entries(sessionData);
                    const taskCount = tasksArray.length;

                    return (
                        <Disclosure as="div" className="mt-2" key={idx}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex h-14 w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2  text-left text-sm font-medium hover:bg-slate-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                        <span className="font-semibold">{`Session ${sortOrder}`}</span>

                                        <div className="flex flex-row items-center">
                                            <Button
                                                variant="outlined"
                                                intent="white"
                                                type="button"
                                                size="md"
                                                className="mr-6 hidden"
                                            >
                                                Download
                                            </Button>
                                            <ChevronUpIcon
                                                className={`${
                                                    open
                                                        ? "rotate-180 transform"
                                                        : ""
                                                } h-5 w-5 text-purple-500`}
                                            />
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                        {tasksArray.map((task, idx) => {
                                            return (
                                                <div
                                                    className="flex w-full flex-col items-start"
                                                    key={idx}
                                                >
                                                    <TeamSessionTask
                                                        task={task}
                                                        session={session}
                                                        team={team}
                                                        taskCount={taskCount}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    );
                })}
            </div>
        </div>
    );
};

export default TeamSessions;
