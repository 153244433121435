import { useNavigate } from "react-router-dom";
import { MyScoreStats } from "@src/models/types";
import { Popover, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import {
    ClipboardDocumentListIcon,
    FaceFrownIcon,
    FaceSmileIcon,
} from "@heroicons/react/24/outline";
import Avatar from "@src/components/Avatar";
import Button from "@src/components/Button";
import { Fragment } from "react";
import { type AxiosError } from "axios";
import { formatDistance } from "date-fns";

const MyScore = (props: {myScore: MyScoreStats}) => {
    const navigator = useNavigate();
    const myScore = props.myScore;
    const {
        avatar,
        badges,
        first_name,
        gender,
        cohort_rank,
        id,
        last_name,
        points,
        school_name,
        team_rank,
        total_in_cohort,
        total_in_team,
        total_points,
    } = myScore;

    return (
        <>
            <section className="py-6">
                {myScore !== undefined &&
                    (myScore.points > 0 ? (
                        <>
                            <h1 className="pb-5 text-2xl font-semibold uppercase">
                                Overview
                            </h1>
                            <div className="mb-8 grid divide-x rounded-lg border-2 bg-white px-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Student</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex gap-4">
                                        <div>
                                            {avatar != null ? (
                                                <>
                                                    <img
                                                        className="h-14 w-14 rounded-full"
                                                        src={avatar}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Avatar
                                                        name={`${first_name} ${last_name}`}
                                                        size="3.5rem"
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-lg font-bold">
                                                {`${first_name} ${last_name}`}
                                            </p>
                                            {/* <p className="text-lg font-bold">{user.}</p> */}
                                            <p className="text-lg text-slate-400">
                                                {school_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Total Points</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            <img
                                                className="h-12 w-12 rounded-full"
                                                src="/images/points.svg"
                                            />
                                        </div>
                                        <div>
                                            <span className="text-7xl font-bold">
                                                {points}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{total_points}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Badges</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex flex-wrap items-center gap-1">
                                        {myScore.badges.length === 0 && (
                                            <p className="w-full text-center text-2xl">
                                                You have no badges!
                                            </p>
                                        )}
                                        {badges
                                            .slice(0, 4)
                                            .map((badge, idx) => (
                                                <>
                                                    <Popover
                                                        key={idx}
                                                        className="relative"
                                                    >
                                                        {({ open }) => (
                                                            <>
                                                                <Popover.Button
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                >
                                                                    <img
                                                                        key={
                                                                            idx
                                                                        }
                                                                        src={
                                                                            badge.poster_url
                                                                        }
                                                                        title={
                                                                            badge.name
                                                                        }
                                                                        className="m-1 h-16 w-16 rounded-full"
                                                                    />
                                                                </Popover.Button>

                                                                {open && (
                                                                    <Transition
                                                                        as={
                                                                            Fragment
                                                                        }
                                                                        enter="transition ease-out duration-200"
                                                                        enterFrom="opacity-0 translate-y-1"
                                                                        enterTo="opacity-100 translate-y-0"
                                                                        leave="transition ease-in duration-150"
                                                                        leaveFrom="opacity-100 translate-y-0"
                                                                        leaveTo="opacity-0 translate-y-1"
                                                                    >
                                                                        <Popover.Panel
                                                                            className="absolute left-2/4 top-2/4 z-10 "
                                                                            static
                                                                        >
                                                                            <div className="w-fit rounded-b-lg rounded-r-lg bg-purple-200 p-4">
                                                                                <p className="mb-1 text-nowrap text-xl font-bold">
                                                                                    {
                                                                                        badge.name
                                                                                    }
                                                                                </p>
                                                                                <p className="mb-4">
                                                                                    {
                                                                                        badge.description
                                                                                    }
                                                                                </p>
                                                                                <p className="text-slate-400">
                                                                                    {formatDistance(
                                                                                        new Date(
                                                                                            badge.created
                                                                                        ),
                                                                                        new Date()
                                                                                    )}{" "}
                                                                                    ago
                                                                                </p>
                                                                            </div>
                                                                        </Popover.Panel>
                                                                    </Transition>
                                                                )}
                                                            </>
                                                        )}
                                                    </Popover>
                                                </>
                                            ))}
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">Team Ranking</h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            {team_rank / total_in_team <=
                                            0.5 ? (
                                                <FaceSmileIcon className="h-14 w-14 text-green-500" />
                                            ) : (
                                                <FaceFrownIcon className="h-14 w-14 text-red-500" />
                                            )}
                                        </div>
                                        <div className="text-blue-500">
                                            <span className="text-7xl font-bold">
                                                {team_rank}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{total_in_team}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="my-4 px-5 py-6">
                                    <h2 className="text-lg ">
                                        Student Ranking
                                    </h2>
                                    <hr className="mb-4 max-w-[24px] border-b-2 border-primary-500" />
                                    <div className="flex items-center gap-4">
                                        <div>
                                            {cohort_rank / total_in_cohort <=
                                            0.5 ? (
                                                <FaceSmileIcon className="h-14 w-14 text-green-500" />
                                            ) : (
                                                <FaceFrownIcon className="h-14 w-14 text-red-500" />
                                            )}
                                        </div>
                                        <div className="text-orange-500">
                                            <span className="text-7xl font-bold">
                                                {cohort_rank}
                                            </span>
                                            <span className="text-4xl font-bold">
                                                /{total_in_cohort}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex justify-center">
                            <div className="flex w-1/2 flex-col items-center justify-center gap-4">
                                <ClipboardDocumentListIcon className="h-56 w-56 stroke-1 text-slate-300" />
                                <p className="text-center text-2xl">
                                    No scores yet, take a session to accumulate
                                    scores
                                </p>
                                <Button
                                    intent="primary"
                                    className="hidden !text-2xl drop-shadow-lg"
                                    href="/students/session/1/objectives"
                                    size="lg"
                                >
                                    Session 1
                                </Button>
                            </div>
                        </div>
                    ))}
            </section>
        </>
    );
};

export default MyScore;
